import React from "react";

const InstagramIcon = () => {
  return (
    <div
      style={{
        imageRendering: "pixelated",
        flexShrink: 0,
        backgroundSize: "100% 100%",
        opacity: 1,
      }}
      aria-hidden="true"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        width="20"
        height="20"
      >
        <path d="M0 0L20 0L20 20L0 20Z" fill="transparent"></path>
        <path
          d="M7.5 10C7.5 8.619 8.619 7.5 10 7.5C11.381 7.5 12.5 8.619 12.5 10C12.5 11.381 11.381 12.5 10 12.5C8.619 12.5 7.5 11.381 7.5 10Z"
          fill="rgb(255,255,255)"
        ></path>
        <path
          d="M13.438 2.188L6.563 2.188C4.146 2.188 2.188 4.146 2.188 6.563L2.188 13.438C2.188 15.854 4.146 17.813 6.563 17.813L13.438 17.813C15.854 17.813 17.813 15.854 17.813 13.438L17.813 6.563C17.813 4.146 15.854 2.188 13.438 2.188Z M10 13.75C7.929 13.75 6.25 12.071 6.25 10C6.25 7.929 7.929 6.25 10 6.25C12.071 6.25 13.75 7.929 13.75 10C13.75 12.071 12.071 13.75 10 13.75Z M14.063 6.875C13.545 6.875 13.125 6.455 13.125 5.938C13.125 5.42 13.545 5 14.063 5C14.58 5 15 5.42 15 5.938C15 6.455 14.58 6.875 14.063 6.875Z"
          fill="rgb(255,255,255)"
        ></path>
      </svg>
    </div>
  );
};

export default InstagramIcon;
